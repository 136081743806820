<template>
  <form
    @submit.prevent="handleSubmit"
    ref="form"
    novalidate
    class="artist-payments__wrapper">
    <header class="mb-xl">
      <Heading
        :tag="'h1'"
        :level="'h3'">
        {{ $t('common.paymentInformations') }}
      </Heading>
    </header>

    <section class="pb-xl">
      <header>
        <Heading
          :tag="'h3'"
          :level="'h3'"
          class="mb-lg"
          id="status">
          1. {{ $t('artist.dashboard.menu.editStatus.title') }}
        </Heading>
      </header>
      <fieldset class="artist-payments__status-wrapper">
        <Select
          v-model="status"
          :options="statusesOptions"
          :label="$t('artist.activity.iAm')"
          :isReadOnly="Boolean(user.StatusName)"
          id="activity-select"
          class="artist-payments__status-select"
          key="statuses"
          required>
        </Select>
        <template v-if="status">
          <template v-if="status === 'INTERMITTENT'">
            <FieldInput
              v-model="nationality"
              :label="$t('artist.dashboard.menu.editStatus.nationality')"
              :placeholder="$t('artist.dashboard.menu.editStatus.nationality')"
              id="nationality-text-input"
              key="nationality"
              type="text"
              required
              class="artist-payments__nationality">
            </FieldInput>
            <Select
              v-model="familyStatus"
              :options="familyStatusOptions"
              :label="$t('artist.dashboard.menu.editStatus.familyStatus')"
              :placeholder="$t('artist.dashboard.menu.editStatus.familyStatus')"
              id="family-status"
              key="family-status"
              class="artist-payments__family-status">
            </Select>
            <fieldset
              name="birthday"
              class="artist-payments__birthday-wrapper">
              <label
                for="birthday-input-0"
                class="field-input__label">
                {{ $t('common.birthday') }}*
              </label>
              <FieldInput
                v-for="(birthdayInput, index) in birthdayInputs"
                v-model="birthday[birthdayInput.type]"
                :key="`birthday-input-${index}`"
                :id="`birthday-input-${index}`"
                :placeholder="birthdayInput.placeholder"
                :minNumberValue="birthdayInput.min"
                :maxNumberValue="birthdayInput.max"
                type="tel"
                required>
              </FieldInput>
              <span
                v-if="birthdayErrors.length"
                :class="[
                  'field-input__error-message',
                  { [`field-input__error-message--${birthdayErrors[0].type}`]: true },
                ]"
                style="grid-column: 1 / -1;">
                {{ birthdayErrors[0].message }}
              </span>
            </fieldset>
            <FieldInput
              v-model="birthdayPlace"
              :label="$t('artist.dashboard.menu.editStatus.birthPlace')"
              :placeholder="$t('artist.dashboard.menu.editStatus.birthPlace')"
              id="birth-place-text-input"
              key="birthday-place"
              type="text"
              required
              class="artist-payments__birth-place">
            </FieldInput>
            <FieldInput
              v-model="birthdayState"
              :label="$t('artist.dashboard.menu.editStatus.birthState')"
              :placeholder="$t('artist.dashboard.menu.editStatus.birthState')"
              id="birth-state-text-input"
              key="birthday-state"
              type="text"
              required
              class="artist-payments__birth-state">
            </FieldInput>
            <FieldInput
              v-model="birthdayCountry"
              :label="$t('artist.dashboard.menu.editStatus.birthCountry')"
              :placeholder="$t('artist.dashboard.menu.editStatus.birthCountry')"
              id="birth-country-text-input"
              key="birthday-country"
              type="text"
              required
              class="artist-payments__birth-country">
            </FieldInput>
            <FieldInput
              v-model="socialSecurityNumber"
              :label="$t('artist.dashboard.menu.editStatus.socialSecurityNumber')"
              :placeholder="$t('artist.dashboard.menu.editStatus.socialSecurityNumber')"
              :minlength="15"
              :maxlength="15"
              id="edit-social-security-text-input"
              type="tel"
              key="social-security-number"
              required
              class="artist-payments__social-security-number">
            </FieldInput>
            <FieldInput
              v-model="leaveNumber"
              :label="$t('artist.dashboard.menu.editStatus.leaveNumber')"
              :placeholder="$t('artist.dashboard.menu.editStatus.leaveNumber')"
              :minlength="7"
              id="edit-leave-text-input"
              type="tel"
              key="leave-number"
              required
              class="artist-payments__leave-number">
            </FieldInput>
            <Select
              v-model="allowance"
              :options="allowanceOptions"
              :label="$t('artist.dashboard.menu.editStatus.system')"
              :placeholder="$t('artist.dashboard.menu.editStatus.system')"
              id="allowance-select"
              key="allowance"
              class="artist-payments__allowance">
            </Select>
          </template>
          <template v-else>
            <FieldInput
              v-model="companyName"
              :label="$t('common.companyName')"
              :placeholder="$t('common.companyName')"
              id="company-name-text-input"
              key="company-name"
              type="text">
            </FieldInput>
            <FieldInput
              v-model="siretNumber"
              :label="$t('artist.dashboard.menu.editStatus.siretNumber')"
              :placeholder="$t('artist.dashboard.menu.editStatus.siretNumber')"
              :minlength="14"
              :maxlength="14"
              id="edit-siret-text-input"
              type="tel"
              key="siret"
              required>
            </FieldInput>
            <FieldInput
              v-model="vatNumber"
              :label="$t('artist.dashboard.menu.editStatus.VATnumber')"
              :placeholder="$t('artist.dashboard.menu.editStatus.VATnumber')"
              :minlength="13"
              :maxlength="13"
              id="edit-vat-text-input"
              key="vat"
              type="tel">
            </FieldInput>
            <div
              v-if="user.URLCardID"
              class="artist-payments__company-valid-id">
              <Icon
                :variant="'check-outline'"
                :color="'valid'"
                class="mr-xs">
              </Icon>
              <Paragraph
                :color="'valid'"
                isBold>
                {{ $t('artist.dashboard.menu.editStatus.validatedID') }}
              </Paragraph>
            </div>
            <CloudinaryUploadWidget
              v-else
              @upload-success="({ url }) => handleDocumentUpload({ url, type: 'ID' })"
              :uploadOptions="widgetUploadPrivateDocumentOptions"
              #default="{ createAndOpenWidget }"
              id="id-document"
              class="artist-payments__company-upload-id">
              <UploadCard
                @click.native="createAndOpenWidget"
                :text="$t('artist.dashboard.menu.editStatus.uploadPrivateDocument')"
                isRequired>
                <template #label>
                  {{ $t('common.uploadFile') }}
                </template>
              </UploadCard>
            </CloudinaryUploadWidget>
            <div
              v-if="kbisUrl || (user.Status && user.Status.Company && user.Status.Company.KBISUrl)"
              class="artist-payments__valid-kbis">
              <Icon
                :variant="'check-outline'"
                :color="'valid'"
                class="mr-xs">
              </Icon>
              <Paragraph
                :color="'valid'"
                isBold>
                {{ $t('artist.dashboard.menu.editStatus.validatedKbis') }}
              </Paragraph>
            </div>
            <CloudinaryUploadWidget
              v-else
              @upload-success="({ url }) => handleDocumentUpload({ url, type: 'KBIS' })"
              :uploadOptions="widgetUploadPrivateDocumentOptions"
              #default="{ createAndOpenWidget }"
              id="kbis-document"
              class="artist-payments__upload-kbis">
              <UploadCard
                @click.native="createAndOpenWidget"
                :text="$t('artist.dashboard.menu.editStatus.uploadKbis')"
                isRequired>
                <template #label>
                  {{ $t('common.uploadFile') }}
                </template>
              </UploadCard>
            </CloudinaryUploadWidget>
            <Checkbox
              v-model="hasRCPro"
              :label="`${$t('artist.dashboard.menu.editStatus.rcPro')}*`"
              id="rc-pro"
              required
              class="artist-payments__rc-pro">
            </Checkbox>
          </template>
        </template>
      </fieldset>
      <template v-if="hasModifiedInfos && status && (status !== 'INTERMITTENT')">
        <Loader
          v-if="isLoading"
          class="mb-xl mr-auto">
        </Loader>
        <Button
          v-else
          type="submit"
          class="mr-auto mb-xl">
          {{ $t('common.save') }}
        </Button>
      </template>
    </section>

    <template v-if="status">
      <section v-if="status === 'INTERMITTENT'">
        <header>
          <Heading
            :tag="'h3'"
            :level="'h3'"
            class="mb-lg">
            2. {{ $t('artist.dashboard.menu.bankInformations') }}
          </Heading>
        </header>
        <fieldset class="artist-payments__status-wrapper">
          <FieldInput
            v-model="iban"
            :label="$t('artist.dashboard.menu.editStatus.iban')"
            :placeholder="$t('artist.dashboard.menu.editStatus.iban')"
            :minlength="27"
            :maxlength="27"
            id="iban-text-input"
            type="tel"
            key="iban"
            required
            class="artist-payments__iban">
          </FieldInput>
          <FieldInput
            v-model="bic"
            :label="$t('artist.dashboard.menu.editStatus.bic')"
            :placeholder="$t('artist.dashboard.menu.editStatus.bic')"
            :minlength="8"
            :maxlength="11"
            id="bic-text-input"
            type="tel"
            key="bic"
            required
            class="artist-payments__bic">
          </FieldInput>
          <div
            v-if="user.URLCardID"
            class="artist-payments__valid-id">
            <Icon
              :variant="'check-outline'"
              :color="'valid'"
              class="mr-xs">
            </Icon>
            <Paragraph
              :color="'valid'"
              isBold>
              {{ $t('artist.dashboard.menu.editStatus.validatedID') }}
            </Paragraph>
          </div>
          <CloudinaryUploadWidget
            v-else
            @upload-success="({ url }) => handleDocumentUpload({ url, type: 'ID' })"
            :uploadOptions="widgetUploadPrivateDocumentOptions"
            #default="{ createAndOpenWidget }"
            id="id-document"
            class="artist-payments__upload-id">
            <UploadCard
              @click.native="createAndOpenWidget"
              :text="$t('artist.dashboard.menu.editStatus.uploadPrivateDocument')"
              isRequired>
              <template #label>
                {{ $t('common.uploadFile') }}
              </template>
            </UploadCard>
          </CloudinaryUploadWidget>
        </fieldset>
      </section>
      <section v-else>
        <header>
          <Heading
            :tag="'h3'"
            :level="'h3'"
            class="mb-lg">
            2. {{ $t('artist.dashboard.menu.stripeAccount') }}
          </Heading>
        </header>
        <Paragraph>
          {{ $t('artist.dashboard.bookings.stripeNotice') }}
        </Paragraph>
      </section>

      <div class="artist-payments__submit-wrapper">
        <Loader
          v-if="isLoading"
          class="mr-auto">
        </Loader>
        <template v-else-if="status && (status !== 'INTERMITTENT') && stripeUrl">
          <Button
            :tag="'a'"
            :href="stripeUrl"
            class="mr-sm">
            {{ $t('artist.dashboard.menu.stripeAccount') }}
          </Button>
          <Icon
            :variant="'stripe-solid'"
            :size="'xl'"
            class="ml-base">
          </Icon>
          <Paragraph
            v-if="!user.IsStripeSetup"
            :color="'alert'"
            :size="'sm'"
            isBold
            class="ml-sm">
            Stripe a besoin d'informations supplémentaires.
          </Paragraph>
        </template>
        <Button
          v-else-if="status && status === 'INTERMITTENT' && hasModifiedInfos"
          type="submit"
          class="mr-sm">
          {{ $t('common.save') }}
        </Button>
      </div>
    </template>
    <Paragraph
      v-if="status"
      :size="'sm'"
      class="pb-xl mt-lg">
      *{{ $t('common.mandatoryField') }}
    </Paragraph>
  </form>
</template>

<script>

import { mapState, mapActions }           from 'vuex';

import Icon                               from '../../../components/atoms/Icon/a-Icon.vue';
import Loader                             from '../../../components/atoms/Loader/a-Loader.vue';
import Heading                            from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                          from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                             from '../../../components/atoms/Button/a-Button.vue';
import Checkbox                           from '../../../components/atoms/Checkbox/a-Checkbox.vue';
import FieldInput                         from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Select                             from '../../../components/atoms/Select/a-Select.vue';
import UploadCard                         from '../../../components/molecules/UploadCard/m-UploadCard.vue';
import CloudinaryUploadWidget             from '../../../components/molecules/CloudinaryUploadWidget/m-CloudinaryUploadWidget.vue';
import COUNTRIES_LIST                     from '../../../constants/countriesList.js';
import ALLOWANCE_OPTIONS                  from '../../../constants/tax-allowance.js';
import STATUSES_OPTIONS                   from '../../../constants/statuses.js';
import FAMILY_STATUSES_OPTIONS            from '../../../constants/family-status-options.js';
import BIRTHDAY_INPUTS                    from '../../../constants/birthday-inputs.js';
import {
  getDateFromDDMMYYYYToMMDDYYYY,
  isValidBirthday,
}                                         from '../../../utils/dateUtils.js';
import {
  widgetUploadPrivateDocumentOptions,
}                                         from '../../../constants/cloudinary-upload-options.js';


export default {
  name: 'p-ArtistPayments',
  components: {
    Icon,
    Loader,
    Heading,
    Paragraph,
    Button,
    Checkbox,
    FieldInput,
    Select,
    UploadCard,
    CloudinaryUploadWidget,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    countriesList: COUNTRIES_LIST,
    familyStatusOptions: FAMILY_STATUSES_OPTIONS,
    allowanceOptions: ALLOWANCE_OPTIONS,
    statusesOptions: STATUSES_OPTIONS,
    birthdayInputs: BIRTHDAY_INPUTS,
    widgetUploadPrivateDocumentOptions,
    isLoading: false,
    hasRCPro: false,
    status: '',
    nationality: '',
    familyStatus: '',
    birthdayPlace: '',
    birthdayState: '',
    birthdayCountry: '',
    siretNumber: '',
    leaveNumber: '',
    vatNumber: '',
    socialSecurityNumber: '',
    allowance: '',
    birthday: {
      day: '',
      month: '',
      year: '',
    },
    birthdayErrors: [],
    iban: '',
    bic: '',
    companyName: '',
    URLCardID: '',
    kbisUrl: '',
  }),
  computed: {
    ...mapState('Stripe', ['stripeUrl']),
    hasModifiedInfos() {
      if (!this.user.StatusName) return true;

      if (!this.user.Status) return false;
      if (this.status === 'INTERMITTENT') {
        const birthday = new Date(getDateFromDDMMYYYYToMMDDYYYY(this.user.Birthday));

        return this.birthday.day !== birthday.getDate()
          || this.birthday.month !== birthday.getMonth() + 1
          || this.birthday.year !== birthday.getFullYear()
          || this.nationality !== this.user.Status.Nationality
          || this.familyStatus !== this.user.Status.FamilyStatus
          || this.birthdayPlace !== this.user.Status.PlaceOfBirth
          || this.birthdayState !== this.user.Status.StateOfBirth
          || this.birthdayCountry !== this.user.Status.CountryOfBirth
          || this.socialSecurityNumber !== this.user.Status.SocialSecurityNumber
          || this.leaveNumber !== this.user.Status.ShowLeaveNumber
          || Number(this.allowance) !== this.user.Status.TaxRate
          || this.iban !== this.user.Status.Iban
          || this.bic !== this.user.Status.Bic;
      } else {
        return this.companyName !== this.user.Status.Name
          || this.siretNumber !== this.user.Status.Siret
          || this.vatNumber !== this.user.Status.VATNumber
          || this.hasRCPro !== this.user.Status.IsRCPro;
      }
    },
  },
  async created() {
    this.prefillFields();
    await this.fetchStripeURL();
  },
  watch: {
    birthday: {
      deep: true,
      handler() {
        const hasFilledBirthdayInput = Object.values(this.birthday).every(date => date && date !== '0');

        if (!hasFilledBirthdayInput) return;

        const isValidBirthday = this.isValidBirthday({
          day: this.birthday.day,
          month: this.birthday.month,
          year: this.birthday.year
        });

        if (!isValidBirthday) return this.birthdayErrors.push({ type: 'alert', message: this.$t('artist.identity.errors.invalidBirthday') });
        else this.birthdayErrors = [];
      },
    },
  },
  methods: {
    ...mapActions({
      uploadPrivateDocument: 'User/UPLOAD_PRIVATE_DOCUMENT',
      editStatusInformations: 'User/EDIT_STATUS_INFORMATIONS',
      fetchStripeURL: 'Stripe/FETCH_STRIPE_URL',
    }),
    isValidBirthday,
    prefillFields() {
      if (this.user.Birthday) {
        const birthday = new Date(getDateFromDDMMYYYYToMMDDYYYY(this.user.Birthday));

        this.birthday.day = birthday.getDate();
        this.birthday.month = birthday.getMonth() + 1;
        this.birthday.year = birthday.getFullYear();
      }

      this.status = this.user?.StatusName ?? '';
      this.siretNumber = this.user?.Status?.Siret ?? '';
      this.leaveNumber = this.user?.Status?.ShowLeaveNumber ?? '';
      this.socialSecurityNumber = this.user?.Status?.SocialSecurityNumber ?? '';
      this.allowance = this.user?.Status?.TaxRate?.toString() ?? '';
      this.familyStatus = this.user?.Status?.FamilyStatus ?? '';
      this.birthdayPlace = this.user?.Status?.PlaceOfBirth ?? '';
      this.birthdayState = this.user?.Status?.StateOfBirth ?? '';
      this.birthdayCountry = this.user?.Status?.CountryOfBirth ?? '';
      this.iban = this.user?.Status?.Iban ?? '';
      this.bic = this.user?.Status?.Bic ?? '';
      this.URLCardID = this.user?.URLCardID ?? '';
      this.kbisUrl = this.user?.Status?.KBISUrl ?? '';
      this.vatNumber = this.user?.Status?.VATNumber ?? '';
      this.hasRCPro = this.user?.Status?.IsRCPro ?? false;
      this.companyName = this.user?.Status?.Name ?? '';
      this.nationality = this.user?.Status?.Nationality ?? '';
    },
    async handleDocumentUpload({ url, type }) {
      await this.uploadPrivateDocument({ url, type });

      if (type === 'ID') this.URLCardID = url;
      if (type === 'KBIS') this.kbisUrl = url;
    },
    async handleSubmit() {
      const isValidForm = this.$refs.form.checkValidity();
      const isValidBirthday = this.isValidBirthday({
        day: this.birthday.day,
        month: this.birthday.month,
        year: this.birthday.year,
      });

      if (!isValidBirthday) return this.birthdayErrors.push({ type: 'alert', message: this.$t('artist.identity.errors.invalidBirthday') });
      else this.birthdayErrors = [];

      if (!isValidForm) return;

      try {
        this.isLoading = true;

        const input = this.status === 'INTERMITTENT'
          ? {
              input: {
                Intermittent: {
                  nationality: this.nationality,
                  familyStatus: this.familyStatus,
                  placeOfBirth: this.birthdayPlace,
                  stateOfBirth: this.birthdayState,
                  countryOfBirth: this.birthdayCountry,
                  socialSecurityNumber: this.socialSecurityNumber.toString(),
                  showLeaveNumber: this.leaveNumber.toString(),
                  iban: this.iban.toString(),
                  bic: this.bic.toString(),
                  taxRate: Number(this.allowance),
                }
              },
            }
          : {
              input: {
                Company: {
                  type: this.status,
                  siret: this.siretNumber.toString(),
                  vatNumber: this.vatNumber.toString(),
                  kbisUrl: this.kbisUrl,
                  isRCPro: this.hasRCPro,
                  Name: this.companyName,
                },
              },
            };

        await this.editStatusInformations(input);

        if (this.status === 'INTERMITTENT') this.$router.push({ name: 'ArtistProfile' });
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">

.artist-payments {
  &__form-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__status-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);
    margin-bottom: var(--space-xl);

    @media screen and ($desktop) {
      display: grid;
      grid-template-columns: repeat(3, calc(33% - var(--space-lg)));
      grid-gap: var(--space-lg);
      align-items: start;
    }
  }

  &__status-select {
    grid-column: 1 / -1;

    @media screen and ($desktop) {
      margin-right: auto;
    }
  }

  &__nationality {
    grid-column: 1 / 2;
  }

  &__family-status {
    grid-column: 2 / 3;
  }

  &__birthday-wrapper {
    grid-column: 3 / 4;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: var(--space-sm);
  }

  &__birth-place {
    grid-column: 1 / 2;
  }

  &__birth-state {
    grid-column: 2 / 3;
  }

  &__birth-city {
    grid-column: 3 / 4;
  }

  &__social-security-number {
    grid-column: 1 / 2;
  }

  &__leave-number {
    grid-column: 2 / 3;
  }

  &__allowance {
    grid-column: 3 / 4;
  }

  &__iban {
    grid-column: 1 / 2;
  }

  &__bic {
    grid-column: 2 / 3;
  }

  &__upload-id {
    grid-column: 3 / 4;
  }

  &__kbis-id {
    grid-column: 1 / 2;
  }

  &__company-valid-id {
    display: flex;
    align-items: center;
    padding: var(--space-xs) 0;

    @media screen and ($desktop) {
      padding: 0;
    }
  }

  &__valid-id {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    padding: var(--space-xs) 0;

    @media screen and ($desktop) {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
      padding: 0;
    }
  }

  &__valid-kbis {
    display: flex;
    align-items: center;
    grid-column: 2 / 3;
  }

  &__rc-pro {
    grid-column: 1 / 3;
  }

  &__vat-taxable {
    grid-column: 1 / 3;
  }

  &__submit-wrapper {
    display: flex;
    align-items: center;
    row-gap: var(--space-base);
    margin-top: var(--space-lg);
  }

  &__separator {
    height: 1px;
    margin: var(--space-md) 0;
    border: none;

    @media screen and ($desktop) {
      margin: calc(.75 * var(--space-xl)) 0;
      background-color: var(--color-grey-semi);
    }
  }
}

</style>
