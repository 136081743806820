import { i18n }     from '../main.js';


export default [
  {
    type: 'day',
    placeholder: i18n.t('common.dd'),
    label: i18n.t('common.day'),
    min: 1,
    max: 31,
    minlength: 2,
    maxlength: 2,
  },
  {
    type: 'month',
    placeholder: i18n.t('common.mm'),
    label: i18n.t('common.month'),
    min: 1,
    max: 12,
    minlength: 2,
    maxlength: 2,
  },
  {
    type: 'year',
    placeholder: i18n.t('common.yyyy'),
    label: i18n.t('common.year'),
    min: 1900,
    max: new Date().getFullYear() - 18,
    minlength: 4,
    maxlength: 4,
  },
];
