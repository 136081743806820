import { i18n }     from '../main.js';


export default [
  {
    label: i18n.t('familyStatuses.separated'),
    value: 'SEPARATED',
  },
  {
    label: i18n.t('familyStatuses.widow'),
    value: 'WIDOW',
  },
  {
    label: i18n.t('familyStatuses.married'),
    value: 'MARIED',
  },
  {
    label: i18n.t('familyStatuses.partnership'),
    value: 'PACSED',
  },
  {
    label: i18n.t('familyStatuses.single'),
    value: 'ALONE',
  },
  {
    label: i18n.t('familyStatuses.divorced'),
    value: 'DIVORCED',
  },
  {
    label: i18n.t('familyStatuses.marital'),
    value: 'MARITAL',
  },
];
