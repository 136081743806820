export default [
	{
		label: 'allowanceList.zero',
		value: '0'
	},
	{
		label: 'allowanceList.twenty',
		value: '20'
	},
	{
		label: 'allowanceList.twentyFive',
		value: '25'
	},
];