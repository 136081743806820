<template>
  <div :class="[wrapperStyle, 'upload-card__wrapper']">
    <header class="upload-card__header">
      <Heading
        :tag="'span'"
        :level="'h5'"
        isOverflow
        class="upload-card__heading">
        {{ text }}
      </Heading>
    </header>

    <input
      v-if="isRequired"
      @invalid.prevent.stop="checkValidity"
      required
      ref="input"
      type="text"
      class="upload-card__required-input"
      tabindex="-1"
    />
    <button
      class="upload-card__input"
      type="button">
      <slot name="label"></slot>
      <Icon
        :variant="'plus-outline'"
        :size="'sm'"
        class="ml-auto">
      </Icon>
    </button>

    <div
      v-if="filename"
      class="upload-card__filename">
      <slot name="filename">
        {{ filename }}
      </slot>
    </div>

    <!-- @slot Custom error  -->
    <slot name="error">
      <span
        v-if="localErrors.length"
        :title="localErrors[0].message"
        class="field-input__error-message">
        {{ localErrors[0].message }}
      </span>
    </slot>
  </div>
</template>

<script>

import Icon                         from '../../atoms/Icon/a-Icon.vue';
import Heading                      from '../../atoms/Heading/a-Heading.vue';
import { handleValidityCheck }      from '../../../utils/DOMUtils.js';


export default {
  name: 'm-UploadCard',
  components: {
    Icon,
    Heading,
  },
  props: {
    text: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localErrors: [],
  }),
  computed: {
    wrapperStyle() {
      return {
        [`upload-card__wrapper--${this.localErrors[0]?.type}`]: Boolean(this.localErrors.length),
      };
    },
  },
  methods: {
    handleValidityCheck,
    checkValidity() {
      const errors = this.handleValidityCheck(this.$refs.input);

      this.localErrors = errors.length ? [ ...errors, ...this.localErrors ] : [];
    },
  },
}

</script>

<style lang="scss">

.upload-card {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    font-family: var(--font-stack-secondary);

    $levels: "valid", "warning", "alert";

    @each $level in $levels {
      &--#{$level} {
        color: var(--color-#{$level});
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 0;
    margin-bottom: var(--space-xs);
  }

  &__heading {
    line-height: var(--lineheight-md);
  }

  &__input {
    grid-row: 2 / 3;
    display: flex;
    align-items: center;
    height: var(--space-main-height);
    padding: 0 var(--space-base);
    border: 1px solid var(--color-grey-semi);
    border-radius: var(--rounded-xs);
    font-size: var(--text-sm);
    line-height: 2;
    color: var(--color-black) !important;
    cursor: pointer;
  }

  &__required-input {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
  }

  &__filename {
    grid-row: 2 / 3;
    display: flex;
    align-items: center;
    font-size: var(--text-xs);
    font-style: italic;
    color: var(--color-grey-neutral);

    @include textOverflowEllipsis();
  }
}

</style>
